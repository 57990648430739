import store from "@/store";
const ENV =
  process.env.NODE_ENV === "staging" || process.env.NODE_ENV === "development"
    ? "ORM_" + process.env.VUE_APP_CUSTOMER_NAME + "_"
    : "ORM_";
const state = () => ({
  LocalSession: {
    reservationDate: { start: null, end: null },
    reservationTime: { start: null, end: null },
  },
});

// getters
const getters = {
  GET_STATION: (state) => state.LocalSession.selectedStation || null,
  GET_STATIONTYPE: (state) => state.LocalSession.stationtype || null,
  GET_STATION_END: (state) =>
    state.LocalSession.selectedStationEnd || state.LocalSession.selectedStation,
  GET_RESERVATIONDATE: (state) =>
    state.LocalSession.reservationDate || { start: null, end: null },
  GET_RESERVATIONDATE_START: (state) =>
    state.LocalSession.reservationDate.start || null,
  GET_RESERVATIONDATE_END: (state) =>
    state.LocalSession.reservationDate.end || null,
  GET_RESERVATIONTIME: (state) =>
    state.LocalSession.reservationTime || { start: null, end: null },
  GET_RESERVATIONTIME_START: (state) =>
    state.LocalSession.reservationTime.start || null,
  GET_RESERVATIONTIME_END: (state) =>
    state.LocalSession.reservationTime.end || null,
  GET_CARGROUP: (state) => state.LocalSession.cargroup || null,
  GET_CARCATEGORY: (state) =>
    state.LocalSession.carcategoryselect ||
    JSON.parse(sessionStorage.getItem(ENV + "selectedCarCategory")) ||
    {},
  GET_CARTYPE: (state) =>
    state.LocalSession.cartypeselect ||
    JSON.parse(sessionStorage.getItem(ENV + "selectedCarType")) ||
    "",
  GET_EQUIPMENT: (state) =>
    state.LocalSession.equipmentselect ||
    JSON.parse(sessionStorage.getItem(ENV + "selectedEquipment")) ||
    {},
  IS_LOGGEDIN: (state) =>
    state.LoginSession ||
    JSON.parse(localStorage.getItem(ENV + "LoginSession")) ||
    null,
  GET_TOKEN: (state) =>
    state.LoginSession ||
    JSON.parse(localStorage.getItem(ENV + "LoginSession")) || {
      token: null,
      expire: null,
    },
};

// actions
const actions = {
  async SET_LOCALSTORAGE({ dispatch }) {
    if (localStorage.getItem(ENV + "userSession")) {
      const Session = JSON.parse(localStorage.getItem(ENV + "userSession"));
      if (Session.reservationDate) {
        await dispatch("SET_RESERVATIONDATE", Session.reservationDate);
      }
      if (Session.reservationTime) {
        await dispatch("SET_RESERVATIONTIME", Session.reservationTime);
      }
      if (Session.selectedStation) {
        await dispatch("SET_STATION_INIT", Session.selectedStation);
      }
      if (Session.selectedStationEnd) {
        await dispatch("SET_STATION_END_INIT", Session.selectedStationEnd);
      }
      if (Session.cargroup) {
        await dispatch("SET_CARGROUP", Session.cargroup);
      }
      if (Session.stationtype) {
        await dispatch("SET_STATIONTYPE", Session.stationtype);
      }
    }
    if (sessionStorage.getItem(ENV + "groupimages")) {
      const Session = JSON.parse(sessionStorage.getItem(ENV + "groupimages"));
      await store.dispatch("cargroup/SET_GROUPIMAGES", Session);
    }
    return ["userSession/SET_LOCALSTORAGE", true];
  },
  async SET_STATION_INIT({ commit }, station) {
    await commit("MUTATE_STATION", station);
  },
  async SET_STATION_END_INIT({ commit }, station) {
    await commit("MUTATE_STATION_END", station);
  },
  async SET_STATION({ commit }, station) {
    await commit("MUTATE_STATION", station);
    const stationsFiltered = this.state.stations.stationlistfiltered;
    for (const key in stationsFiltered) {
      if (Object.hasOwnProperty.call(stationsFiltered, key)) {
        const element = stationsFiltered[key];
        const found = element.find((x) => x.id == station);
        if (found && key !== "ALL") {
          store.dispatch("userSession/SET_STATIONTYPE", key);
          return;
        }
      }
    }
    await store.dispatch("stations/SET_OPENINGTIMES", station);
  },
  async SET_STATION_END({ commit }, station) {
    await commit("MUTATE_STATION_END", station);
    await store.dispatch("stations/SET_OPENINGTIMES", station);
  },
  SET_STATIONTYPE({ commit }, type) {
    commit("MUTATE_STATIONTYPE", type);
  },
  SET_USERLOCATION({ commit }, location) {
    commit("MUTATE_USERLOCATION", location);
  },
  SET_RESERVATIONDATE({ commit }, range) {
    commit("MUTATE_DATE", range);
  },
  SET_RESERVATIONTIME({ commit }, range) {
    commit("MUTATE_TIME", range);
  },
  SET_CARGROUP({ commit }, carId) {
    commit("MUTATE_CARGOUP", carId);
  },
  SET_CARCATEGORY({ commit }, carcategory) {
    commit("MUTATE_CARCATEGORY", carcategory);
  },
  SET_EQUIPMENT({ commit }, equipment) {
    commit("MUTATE_EQUIPMENT", equipment);
  },
  SET_CARTYPE({ commit }, cartype) {
    commit("MUTATE_CARTYPE", cartype);
  },
  SET_LOGGEDINSESSION({ commit }, usersession) {
    commit("MUTATE_LOGGEDINSESSION", usersession);
  },
  LOGOUT({ commit }) {
    commit("MUTATE_LOGOUT");
  },
};

// mutations
const mutations = {
  MUTATE_STATION(state, station) {
    state.LocalSession["selectedStation"] = station;
    localStorage.setItem(
      ENV + "userSession",
      JSON.stringify(state.LocalSession)
    );
  },
  MUTATE_STATION_END(state, station) {
    state.LocalSession["selectedStationEnd"] = station;
    if (station == "startend") delete state.LocalSession.selectedStationEnd;
    localStorage.setItem(
      ENV + "userSession",
      JSON.stringify(state.LocalSession)
    );
  },
  MUTATE_STATIONTYPE(state, type) {
    state.LocalSession["stationtype"] = type;
    localStorage.setItem(
      ENV + "userSession",
      JSON.stringify(state.LocalSession)
    );
  },
  MUTATE_USERLOCATION(state, location) {
    state.LocalSession["userLocation"] = location;
    localStorage.setItem(
      ENV + "userSession",
      JSON.stringify(state.LocalSession)
    );
  },
  MUTATE_DATE(state, date) {
    state.LocalSession["reservationDate"] = JSON.parse(JSON.stringify(date));
    localStorage.setItem(
      ENV + "userSession",
      JSON.stringify(state.LocalSession)
    );
  },
  MUTATE_TIME(state, time) {
    state.LocalSession["reservationTime"] = JSON.parse(JSON.stringify(time));
    localStorage.setItem(
      ENV + "userSession",
      JSON.stringify(state.LocalSession)
    );
  },

  MUTATE_DATE_START(state, date) {
    state.LocalSession["reservationDate"].start = JSON.parse(
      JSON.stringify(date)
    );
    localStorage.setItem(
      ENV + "userSession",
      JSON.stringify(state.LocalSession)
    );
  },
  MUTATE_DATE_END(state, date) {
    state.LocalSession["reservationDate"].end = JSON.parse(
      JSON.stringify(date)
    );
    localStorage.setItem(
      ENV + "userSession",
      JSON.stringify(state.LocalSession)
    );
  },
  MUTATE_TIME_START(state, time) {
    state.LocalSession["reservationTime"].start = JSON.parse(
      JSON.stringify(time)
    );
    localStorage.setItem(
      ENV + "userSession",
      JSON.stringify(state.LocalSession)
    );
  },
  MUTATE_TIME_END(state, time) {
    state.LocalSession["reservationTime"].end = JSON.parse(
      JSON.stringify(time)
    );
    localStorage.setItem(
      ENV + "userSession",
      JSON.stringify(state.LocalSession)
    );
  },
  MUTATE_CARGOUP(state, car) {
    state.LocalSession["cargroup"] = JSON.parse(JSON.stringify(car));
    localStorage.setItem(
      ENV + "userSession",
      JSON.stringify(state.LocalSession)
    );
  },
  MUTATE_CARCATEGORY(state, carcategory) {
    state.LocalSession["carcategoryselect"] = carcategory;
    sessionStorage.setItem(
      ENV + "selectedCarCategory",
      JSON.stringify(carcategory)
    );
  },
  MUTATE_CARTYPE(state, cartype) {
    state.LocalSession["cartypeselect"] = cartype;
    sessionStorage.setItem(ENV + "selectedCarType", JSON.stringify(cartype));
  },
  MUTATE_EQUIPMENT(state, equipment) {
    state.LocalSession["equipmentselect"] = equipment;
    sessionStorage.setItem(
      ENV + "selectedEquipment",
      JSON.stringify(equipment)
    );
  },
  MUTATE_LOGGEDINSESSION(state, usersession) {
    const expireAt = JSON.stringify(3600 * 1000 + new Date().getTime());
    const session = { token: usersession, expire: expireAt };
    state.LoginSession = session;
    localStorage.setItem(ENV + "LoginSession", JSON.stringify(session));
  },
  MUTATE_LOGOUT(state) {
    state.LoginSession = null;
    localStorage.removeItem(ENV + "LoginSession");
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
