<template>
  <teleport v-if="widget" to="#crentFooter">
    <div class="orm-container-fluid orm-crentFooter">
      <div class="orm-container">
        <div class="orm-row center-xs">
          <div class="orm-col-xs-11">
            <div class="orm-row orm-crentFooter__pageLinks">
              <div class="orm-col-xs-6 orm-col-sm-4 orm-col-md-2 orm-mb-32">
                <div class="orm-headline__h4 footer">{{ i18n("footer/stations") }}:</div>
                <ul>
                  <li v-for="station in stations" :key="station">
                    <router-link
                      :to="{
                        name: 'station',
                        params: { name: slugMe(station.name) },
                      }"
                      >{{ station.name }}</router-link
                    >
                  </li>
                </ul>
              </div>
              <div class="orm-col-xs-6 orm-col-sm-4 orm-col-md-2 orm-mb-32" v-if="stationsCS.lenght > 0">
                <div class="orm-headline__h4 footer">{{ i18n("footer/sharestations") }}:</div>
                <ul>
                  <li v-for="station in stationsCS" :key="station">
                    <router-link
                      :to="{
                        name: 'station',
                        params: { name: slugMe(station.name) },
                      }"
                      >{{ station.name }}</router-link
                    >
                  </li>
                </ul>
              </div>
              <div class="orm-col-xs-12 orm-col-sm-4 orm-col-md-2 orm-mb-32">
                <div class="orm-headline__h4 footer">{{ i18n("footer/services") }}:</div>

                <div class="orm-row">
                  <div class="orm-col-xs-6 orm-col-sm-12" v-html="i18n('footer/services_links')">
                  </div>
                </div>
              </div>
              <div class="orm-col-xs-12 orm-col-md-6">
                <div class="orm-headline__h4 footer">{{ i18n("footer/title") }}</div>
                <div v-html="i18n('footer/description')"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </teleport>
</template>

<script>
import { defineComponent } from "vue";
import store from "@/store";
import { slugMe } from "@/constants/formater";
import i18n from "@/mixins/mixins";

export default defineComponent({
  name: "Footer",
  components: {},
  mixins:[i18n],
  setup() {
    let widget = false;
    const myEle = document.getElementById("crentFooter");
    if (myEle !== null) {
      widget = true;
    }
    const stations = store.getters["stations/GET_STATIONS"];
    const stationsCS = [];
    for (const station in stations) {
      if (stations[station].is_sharing_station == true) {
        stationsCS.push(stations[station]);
      }
    }
    // console.log(store.state.stations);

    return {
      widget,
      stations,
      stationsCS,
      slugMe,
    };
  },
});
</script>
