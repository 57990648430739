<template>
  <div v-if="!google"></div>
</template>

<script>
import { USER_MARKER_ICON_CONFIG } from "@/constants/mapSettings";
// import { getDistanceFromLatLonInKm } from "@/constants/formater";

import store from "@/store";

export default {
  props: {
    google: {
      type: Object,
      required: false,
    },
    map: {
      type: Object,
      required: false,
    },
    bounds: {
      type: Object,
      required: false,
    },
    stationtype: {
      default: "ALL",
      required: false,
    },
  },

  async created() {
    const { Marker } = window.google.maps;

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        const pos = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
        const stationtype = this.stationtype;
        store.dispatch("userSession/SET_USERLOCATION", pos).then(() => {
          store.dispatch("stations/SORT_STATIONS", { pos, stationtype });
        });

        this.bounds.extend(pos);
        this.map.fitBounds(this.bounds);
        new Marker({
          position: pos,
          marker: "user",
          map: this.map,
          icon: USER_MARKER_ICON_CONFIG,
        });
      });
    }
  },
};
</script>
