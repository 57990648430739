import apiCall from "../../api/crentAPI";
import store from "@/store";
import {
  getDistanceFromLatLonInKm,
  formateDatetime,
  formateDate,
  formatHour,
  formateTimeArray,
  slugMe,
} from "@/constants/formater";
const ENV =
  process.env.NODE_ENV === "staging" || process.env.NODE_ENV === "development"
    ? "ORM_" + process.env.VUE_APP_CUSTOMER_NAME + "_"
    : "ORM_";

// initial state
const state = () => ({
  stationlist: [],
  stationlistfiltered: {},
  stationlistsorted: [],
  stationlistsortedfiltered: [],
  opentimes: false,
  closedDays: [],
});

// getters
const getters = {
  GET_STATIONS: (state) => state.stationlist || [],
  GET_STATIONS_SORTED: (state) => state.stationlistsorted || [],
  GET_STATIONS_FILTERED: (state) => state.stationlistfiltered || [],
  GET_STATION_BY_ID: (state) => (stationID) => {
    return state.stationlist.find((station) => station.id === stationID);
  },
  GET_STATION_BY_NAME: (state) => (name) => {
    return state.stationlist.find((station) => station.name === name);
  },
  GET_STATION_BY_SLUG: (state) => (name) => {
    return state.stationlist.find((station) => slugMe(station.name) === name);
  },
  GET_OPENINGTIMES: (state) => state.opentimes || null,
  GET_CLOSEDDAYS: (state) => state.closedDays,
};

// actions
const actions = {
  async SET_ALLSTATIONS({ commit }) {
    const types = ["ALL", "RENT", "SHARE", "ABO", "SPECIAL"];
    const payload = "";
    // eslint-disable-next-line
    let status = false;
    for (const type of types) {
      await apiCall.get("/api/rent/stations", { type: type }, (stations) => {
        if (stations.length > 0) {
          commit("MUTATE_STATIONS_FILTERED", { type, stations });
        }
        if (stations.error || stations.errorCode) {
          status = false;
          return ["stations/SET_ALLSTATIONS", false];
        } else {
          status = true;
        }
        // if (store.getters["userSession/GET_STATION"] == null) {
        //   store.dispatch("userSession/SET_STATION_INIT", stations[0].id);
        // }
      });
    }
    await apiCall.get("/api/rent/stations", payload, (stations) => {
      commit("MUTATE_STATIONS", stations);
      if (stations.error || stations.errorCode) {
        status = false;
        return ["stations/SET_ALLSTATIONS", false];
      } else {
        status = true;
      }
    });
    return ["stations/SET_ALLSTATIONS", status];
  },

  async SET_OPENINGTIMES({ commit }, station) {
    const date = await store.getters["userSession/GET_RESERVATIONDATE"];
    const time = await store.getters["userSession/GET_RESERVATIONTIME"];
    const startstation = await store.getters["userSession/GET_STATION"];
    const endstation = await store.getters["userSession/GET_STATION_END"];
    const language = await store.getters["general/GET_LANGUAGE"];
    const timeNow = formatHour(new Date());

    let startTimeDate = "";
    let endTimeDate = "";
    let st = "";

    if (date.start) {
      if (time.start) {
        st = time.start + ":00";
      } else {
        st = timeNow + ":00";
      }
      startTimeDate = date.start + "T" + st + "Z";
    } else {
      startTimeDate = formateDatetime(Date.now());
    }
    if (date.end) {
      if (time.end) {
        st = time.end + ":00";
      } else {
        st = timeNow + ":00";
      }
      endTimeDate = date.end + "T" + st + "Z";
    } else {
      endTimeDate = formateDatetime(Date.now());
    }

    let payload = {};
    if (station == "preload" || startstation == null) {
      payload = {
        clientDateStartingPoint: formateDatetime(Date.now()),
        selectedStartDateTime: startTimeDate,
        selectedEndDateTime: endTimeDate,
        language: language,
      };
    } else {
      payload = {
        // action: "getStationData",
        // eslint-disable-next-line
        startstation: startstation,
        // eslint-disable-next-line
        endstation: endstation,
        // eslint-disable-next-line
        clientDateStartingPoint: formateDatetime(Date.now()),
        selectedStartDateTime: startTimeDate,
        selectedEndDateTime: endTimeDate,
        language: language,
      };
    }
    let status = false;
    // eslint-disable-next-line
    await apiCall.get("/api/rent/stations/opentimes", payload, (result) => {
      if (result.error || result.errorCode) {
        status = false;
        return ["stations/SET_OPENINGTIMES", status];
      } else {
        status = true;
      }
      commit("MUTATE_OPENINGTIMES", result);

      const daterange = {
        start: formateDate(result.selected_start_date),
        end: formateDate(result.selected_end_date),
      };
      const timerange = {
        start: formateTimeArray(result.selected_start_date),
        end: formateTimeArray(result.selected_end_date),
      };

      store.dispatch("userSession/SET_RESERVATIONDATE", daterange);
      store.dispatch("userSession/SET_RESERVATIONTIME", timerange);
    });
    return ["stations/SET_OPENINGTIMES", status];
  },
  SORT_STATIONS({ commit }, payload) {
    commit("MUTATE_DISTANCE", payload);
  },
};

// mutations
const mutations = {
  MUTATE_STATIONS(state, stations) {
    state.stationlist = stations;
    // state.stationlistsorted = stations;
    localStorage.setItem(ENV + "stations", JSON.stringify(stations));
  },
  MUTATE_STATIONS_FILTERED(state, item) {
    state.stationlistfiltered[item.type] = item.stations;
    state.stationlistsorted[item.type] = item.stations;
    localStorage.setItem(
      ENV + "stationsFiltered",
      JSON.stringify(state.stationlistfiltered)
    );
  },
  // SETS CLOSED DAYS FOR DATEPICKER
  MUTATE_OPENINGTIMES(state, time) {
    state.opentimes = time;
    const closedDay = time.closed_days_config;
    const closedDayArray = [];
    closedDay.forEach((element) => {
      const date = element.date.split("T");
      const found = closedDayArray.find((element) => element === date[0]);
      if (found == undefined) closedDayArray.push(date[0]);
    });
    state.closedDays = closedDayArray;
  },
  MUTATE_DISTANCE(state, payload) {
    const stationtype = payload.stationtype;
    const stations = JSON.parse(
      JSON.stringify(state.stationlistfiltered[stationtype])
    );

    for (const element in stations) {
      const distance = getDistanceFromLatLonInKm(
        stations[element].latitude,
        stations[element].longitude,
        payload.pos
      );
      stations[element].distance = distance;
    }

    function compare(a, b) {
      if (a.distance < b.distance) return -1;
      if (a.distance > b.distance) return 1;
      return 0;
    }
    const newsort = JSON.parse(JSON.stringify(stations));
    newsort.sort(compare);
    state.stationlistsorted[stationtype] = newsort;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
