<template>
  <div class="orm-login-widget text-left" v-click-outside="handleClick">
    <div v-if="showLogo">
      <div class="orm-reservationHeader__logo orm-pt-0">
        <router-link
          v-if="env.VUE_APP_STANDALONE == 'true'"
          :to="{ name: 'Home' }"
          title="Home"
        >
          <div
            class="orm-reservationHeader__logo_image"
            :style="{
              backgroundImage: 'url(' + logo + ')',
              height: logoheight + 'px',
            }"
          ></div>
        </router-link>
        <a v-else :href="env.VUE_APP_BASE_URL"
          ><div
            class="orm-reservationHeader__logo_image"
            :style="{
              backgroundImage: 'url(' + logo + ')',
              height: logoheight + 'px',
            }"
          ></div
        ></a>
      </div>
    </div>
    <p v-if="!forgotpw" class="orm-login-headline">
      {{ i18n("customer/login/title") }}
    </p>
    <p v-if="forgotpw" class="orm-login-headline">
      {{ i18n("customer/login/forgott_pw") }}
    </p>
    <FormInput
      v-if="!sendSuccess && !requestLoading && !showSuccess"
      v-model="form.email"
      type="text"
      my-id="email"
      :my-label="i18n('form/label/email')"
      :is-required="true"
      :error="validate.email"
    />
    <FormPwd
      v-if="!forgotpw && !requestLoading && !showSuccess"
      v-model="form.password"
      type="text"
      my-id="password"
      :my-label="i18n('form/label/password') || 'Passwort'"
      :is-required="true"
      :error="validate.password"
    />
    <div v-if="errors" class="orm-login-error">
      <small v-for="error in errors" :key="error">{{ error }}</small>
    </div>
    <div v-if="sendSuccess">
      {{ i18n("customer/login/mail_success") }}
      <div
        @click="
          forgotpw = false;
          sendSuccess = false;
        "
        class="orm-btn orm-login-widget-btnlistener"
      >
        {{ i18n("customer/login/back_to_login") }}
      </div>
    </div>
    <div class="orm-pwdlink">
      <p
        class="orm-login-widget-btnlistener"
        v-if="!forgotpw && !requestLoading"
        @click="forgotpassword"
      >
        {{ i18n("customer/login/forgott_pw") }}?
      </p>
    </div>
    <button
      class="orm-btn orm-login-widget-btnlistener"
      v-if="!forgotpw && !requestLoading"
      @click="authenticate"
    >
      {{ i18n("customer/login/button_submit") }}
    </button>
    <button
      class="orm-btn orm-login-widget-btnlistener"
      v-if="forgotpw && !sendSuccess && !requestLoading && !showSuccess"
      @click="requestPassword"
    >
      {{ i18n("customer/login/reset_pw") }}
    </button>

    <div v-if="requestLoading" class="text-center">
      <div class="orm-loader-spinner">
        <i class="fa fa-spinner-third orm-rotate360"></i>
      </div>
      <p v-if="forgotpw">Anfrage wird bearbeitet...</p>
    </div>
    <div v-if="showSuccess" class="text-center">
      <p>E-Mail mit Link zum Passwort zurücksetzen wurde verschickt.</p>
      <button
        class="orm-btn orm-login-widget-btnlistener"
        v-if="showSuccess"
        @click="
          showSuccess = false;
          forgotpw = false;
        "
      >
        Zurück zum Login
      </button>
    </div>
  </div>
</template>
<script>
import store from "@/store";
import i18n from "@/mixins/mixins";
import FormInput from "@/components/ui/INPUTS/FormInput.vue"; // @ is an alias to /src
import FormPwd from "@/components/ui/INPUTS/FormPwd.vue"; // @ is an alias to /src
export default {
  emits: ["getLogin", "showCustomerOverlay"],
  mixins: [i18n],
  components: { FormInput, FormPwd },
  props: {
    showLogo: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const logoheight = process.env.VUE_APP_CUSTOMER_LOGO_HEIGHT_RESERVATION;
    const clientdata = store.getters["general/GET_CLIENT_DATA"];
    const env = process.env;
    let logo;
    // eslint-disable-next-line
    if (clientdata["reservationheader-logo"]) {
      if (clientdata["reservationheader-logo"].search("http") != "-1") {
        // eslint-disable-next-line
        logo = clientdata["reservationheader-logo"];
      } else {
        // eslint-disable-next-line
        logo =
          process.env.VUE_APP_BASE_URL + clientdata["reservationheader-logo"];
      }
    }
    return { logo, logoheight, env };
  },
  data() {
    return {
      form: {
        email:
          process.env.NODE_ENV === "development" ||
          process.env.VUE_APP_DEMO === "true"
            ? "webmodul@cx9.de"
            : "",
        password:
          process.env.NODE_ENV === "development" ||
          process.env.VUE_APP_DEMO === "true"
            ? "gNSISkP8"
            : "",
      },
      validate: {
        email: false,
        password: false,
      },
      forgotpw: false,
      sendSuccess: false,
      errors: [],
      requestLoading: false,
      showSuccess: false,
    };
  },
  methods: {
    validateForm() {
      this.errors = [];
      for (const formfield in this.validate) {
        if (Object.hasOwnProperty.call(this.validate, formfield)) {
          const element = this.form[formfield];
          if (formfield == "email") {
            if (!this.validEmail(element)) {
              this.validate[formfield] = true;
              // this.errors.push(formfield + " required.");
            } else {
              this.validate[formfield] = false;
            }
          } else if (!element) {
            this.validate[formfield] = true;
            // this.errors.push(formfield + " required.");
          } else {
            this.validate[formfield] = false;
          }
        }
      }
      if (this.errors.length > 0) {
        return false;
      } else {
        return true;
      }
    },
    validEmail(email) {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    authenticate() {
      if (!this.validateForm()) {
        return false;
      }
      this.requestLoading = true;
      store
        .dispatch("customer/AUTHENTICATE", {
          email: this.form.email,
          password: this.form.password,
        })
        .then((result) => {
          if (result == true) {
            store.dispatch("customer/GET_USER_DATA").then(() => {
              this.$emit("getLogin", true);
            });
          } else {
            this.requestLoading = false;
            this.validate.email = true;
            this.validate.password = true;
            const message =
              this.i18n("customer/login/error_login") ||
              "Emailadresse oder Passwort falsch.";
            this.errors.push(message);
          }
        });
    },
    forgotpassword() {
      this.validate.email = false;
      this.validate.password = false;
      this.errors = [];
      this.forgotpw = true;
    },
    async requestPassword() {
      this.requestLoading = true;
      await store
        .dispatch("customer/FORGOTPASSWORD", {
          email: this.form.email,
          return_url: process.env.VUE_APP_CUSTOMER_PATH + "forgot/",
        })
        .then((result) => {
          if (result) {
            console.log("success");
            this.showSuccess = true;
            this.requestLoading = false;
          } else {
            // this.showSuccess = true;
            if (process.env.VUE_APP_STANDALONE == "true") {
              this.$router.push({ name: "Home" });
            } else {
              window.location.href = process.env.VUE_APP_BASE_URL;
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    handleClick(ev) {
      if (!ev.target.classList.contains("orm-login-widget-btnlistener")) {
        // console.log(ev.target.classList);
        this.$emit("showCustomerOverlay", false);
      }
    },
  },
};
</script>
