<template>
  <div
    class="orm-customer-widget"
    v-if="store.getters['ORMgeneral/GET_MODULE']('ORM_LOGIN') == 'J'"
  >
    <div>
      <button
        v-if="!islogin"
        class="orm-btn--icon orm-login-widget-btnlistener"
        @click="showCustomerOverlay = !showCustomerOverlay"
      >
        <i class="fa fa-sign-in"></i> Anmelden
      </button>
      <button
        v-if="islogin"
        class="orm-btn--loggedin orm-login-widget-btnlistener"
        :class="loginicontext ? 'orm-btn--loggedin--text' : ''"
        @click="showCustomerOverlay = !showCustomerOverlay"
      >
        <span v-if="loginicontext" class="orm-login-widget-btnlistener"
          >Mein Account</span
        >
        <div
          class="orm-login-widget-btnlistener"
          :class="
            loginicontext
              ? 'orm-login__icon--loggedin orm-login__icon--loggedin--text'
              : 'orm-login__icon--loggedin'
          "
        >
          <svg
            width="100%"
            height="100%"
            viewBox="0 0 33 33"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            xml:space="preserve"
            xmlns:serif="http://www.serif.com/"
            style="fill-rule:evenodd;clip-rule:evenodd;stroke-linecap:round;stroke-linejoin:round;"
          >
            <g>
              <g>
                <path
                  d="M23.494,24.306l0,-1.811c0,-1.988 -1.635,-3.623 -3.623,-3.624l-7.247,0c-1.988,0 -3.624,1.636 -3.624,3.624l0,1.812"
                  style="fill:none;fill-rule:nonzero;stroke:#24282c;stroke-width:2px;"
                />
                <path
                  d="M19.871,11.624c-0,1.988 -1.636,3.624 -3.624,3.624c-1.988,-0 -3.624,-1.636 -3.624,-3.624c0,-1.988 1.636,-3.624 3.624,-3.624c0,-0 0.001,-0 0.001,0c1.988,0 3.624,1.636 3.624,3.624l-0.001,0Z"
                  style="fill:none;fill-rule:nonzero;stroke:#24282c;stroke-width:2px;"
                />
              </g>
              <g>
                <circle cx="16.5" cy="16.5" r="16.5" style="fill:none;" />
                <circle
                  cx="16.5"
                  cy="16.5"
                  r="15.5"
                  style="fill:none;stroke:#24282c;stroke-width:2px;stroke-linecap:butt;stroke-linejoin:miter;"
                />
              </g>
            </g>
          </svg>
        </div>
      </button>
    </div>
    <div
      v-if="showCustomerOverlay"
      class="orm-customer-widget-overlay orm-dropdown-menu"
    >
      <Login
        v-if="islogin == null"
        @get-login="getLogin"
        @show-customer-overlay="showCustomerOverlay = !showCustomerOverlay"
      />
      <Loggedin
        v-if="islogin"
        @get-logout="getLogin"
        @show-customer-overlay="showCustomerOverlay = !showCustomerOverlay"
        @showContact="
          showContact = !showContact;
          showCustomerOverlay = !showCustomerOverlay;
        "
      />
    </div>
  </div>
  <Contact
    v-if="showContact"
    type="general"
    @dismiss="showContact = !showContact"
  />
</template>

<script>
import { defineComponent } from "vue";
import store from "@/store";
// import { slugMe } from "@/constants/formater";
import i18n from "@/mixins/mixins";

import Login from "@/components/customer/login.vue"; // @ is an alias to /src
import Loggedin from "@/components/customer/loggedin.vue"; // @ is an alias to /src
import Contact from "@/components/modals/modal-contact.vue"; /* webpackChunkName: 'Contact.chunk' */

export default defineComponent({
  name: "Footer", // lustiger name ;P
  components: { Login, Loggedin, Contact },
  mixins: [i18n],
  emits: ["loginState"],
  props: {
    teleport: {
      default: true,
    },
  },
  data() {
    return {
      islogin: null,
      showCustomerOverlay: false,
      showContact: false,
    };
  },
  setup() {
    // console.log(props);
    let widget = false;
    const myEle = document.getElementById("crentCustomerWidget");
    let loginicontext = true;
    const crentWrapper = document.getElementById("crentReservation");
    if (crentWrapper !== null) {
      loginicontext =
        crentWrapper.dataset.loginicontext === "true" ? true : false;
    }
    if (myEle !== null) {
      widget = true;
      loginicontext = myEle.dataset.loginicontext === "true" ? true : false;
    }
    return {
      widget,
      loginicontext,
      store,
    };
  },
  async mounted() {
    this.islogin = await store.getters["userSession/IS_LOGGEDIN"];
  },
  methods: {
    async getLogin() {
      this.islogin = await store.getters["userSession/IS_LOGGEDIN"];
      this.$emit("loginState", true);
    },
  },
});
</script>
