<template>
  <teleport v-if="widget" to="#crentHeader">
    <div class="orm-header">
      <div class="orm-container">
        <div class="orm-row middle-xs">
          <div class="orm-col-xs-6 orm-col-sm-6">
            <div class="orm-reservationHeader__logo">
              <router-link
                v-if="env.VUE_APP_STANDALONE == 'true'"
                :to="{ name: 'Home' }"
                title="Home"
              >
                <div
                  class="orm-reservationHeader__logo_image"
                  :style="{
                    backgroundImage: 'url(' + logo + ')',
                    height: logoheight + 'px',
                  }"
                ></div>
              </router-link>
              <a v-else :href="env.VUE_APP_BASE_URL"
                ><div
                  class="orm-reservationHeader__logo_image"
                  :style="{
                    backgroundImage: 'url(' + logo + ')',
                    height: logoheight + 'px',
                  }"
                ></div
              ></a>
            </div>
          </div>
          <div class="orm-col-xs-6 orm-col-sm-6 text-right">
            <ul class="orm-headerNavigation text-right">
              <li v-if="env.VUE_APP_STATIONPAGE === 'true'">
                <router-link :to="{ name: 'stations' }">
                  {{ i18n("general/stations") || "Stationen" }}
                </router-link>
              </li>
              <li v-if="env.VUE_APP_VEHICLEPAGE === 'true'">
                <router-link :to="{ name: 'fahrzeuge' }">{{
                  i18n("general/vehicles")
                }}</router-link>
              </li>
              <li>
                <CustomerWidget
                  :teleport="false"
                  :loginicontext="loginicontext"
                />
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </teleport>
</template>

<script>
import { defineComponent } from "vue";
import store from "@/store";
import i18n from "@/mixins/mixins";
import CustomerWidget from "@/components/widgets/CustomerWidget.vue"; // @ is an alias to /src

// import dropdown from "@/components/ui/dropdown.vue"; // @ is an alias to /src

export default defineComponent({
  name: "Footer",
  components: { CustomerWidget },
  mixins: [i18n],
  setup() {
    let widget = false;
    let loginicontext = true;
    const myEle = document.getElementById("crentHeader");
    if (myEle !== null) {
      loginicontext = myEle.dataset.loginicontext === "false" ? false : true;
      widget = true;
    }
    const clientdata = store.getters["general/GET_CLIENT_DATA"];
    let logo;
    // eslint-disable-next-line
    if (clientdata["reservationheader-logo"]) {
      if (clientdata["reservationheader-logo"].search("http") != "-1") {
        // eslint-disable-next-line
        logo = clientdata["reservationheader-logo"];
      } else {
        // eslint-disable-next-line
        logo =
          process.env.VUE_APP_BASE_URL + clientdata["reservationheader-logo"];
      }
    }

    const stations = store.getters["stations/GET_STATIONS"];
    const logoheight = process.env.VUE_APP_CUSTOMER_LOGO_HEIGHT_HOME;
    const env = process.env;
    // const stationsCS = [];
    // for (const station in stations) {
    //   if (stations[station].is_sharing_station == true) {
    //     stationsCS.push(stations[station]);
    //   }
    // }
    // console.log(store.state.stations);

    return {
      widget,
      store,
      stations,
      logoheight,
      loginicontext,
      env,
      logo,
      // stationsCS,
    };
  },
});
</script>
